import { FormattedMessage } from 'react-intl';
import type React from 'react';

const customerCenterLink = 'https://marketplace.rio.cloud/customerCenter/partners';

export const CustomerCenterLink = () => (
    <a className='margin-10' href={customerCenterLink} rel='noreferrer' target='_blank'>
        <FormattedMessage id='contactForm.partner.customerCenterLinkText' />
    </a>
);

export const CustomerCenterLinkWrapper = (props: { children: React.ReactNode }) => (
    <a href={customerCenterLink} rel='noreferrer' target='_blank'>
        {props.children}
    </a>
);
