import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { accessToken } from '../../../configuration/tokenHandling/accessToken';
import { mapApiPartnerConnections, mapApiPartners } from './partnerMapper';
import type { PartnerConnection } from './partner.types';
import { v4 as uuidv4 } from 'uuid';
import { createSelector } from '@reduxjs/toolkit';
import { extractQueryParameter } from '../../utils/queryParameters';

export interface Partner {
    partnerName: string;
    partnerDisplayName: string;
    privacyPolicyUrl: string;
}

function findPartner(partners: Partner[]): Partner | undefined {
    const partnerName = extractQueryParameter('partnerId');

    if (partnerName !== undefined) {
        return partners.find(partner => partner.partnerName === partnerName);
    }
        return undefined;
}

export const partnerApi = createApi({
    reducerPath: 'partnerApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.PARTNER_SERVICE,
        prepareHeaders: headers => {
            headers.set('Authorization', `Bearer ${accessToken.getAccessToken()}`);
            return headers;
        },
    }),
    tagTypes: ['PartnerConnection', 'Partners'],
    endpoints: builder => ({
        getPartnerConnections: builder.query<PartnerConnection[], string>({
            query: accountId => ({
                url: `/accounts/${accountId}/connections`,
            }),
            transformResponse: mapApiPartnerConnections,
            providesTags: ['PartnerConnection'],
        }),
        getPartners: builder.query<Partner[], void>({
            query: () => ({
                url: '/partners',
            }),
            transformResponse: mapApiPartners,
            providesTags: ['Partners'],
        }),
        putPartnerConnection: builder.mutation<string, { partnerName: string; accountId: string }>({
            query: ({ partnerName, accountId }) => {
                const id = uuidv4();
                return {
                    url: `/accounts/${accountId}/connections/${id}`,
                    method: 'PUT',
                    body: {
                        id,
                        account_id: accountId,
                        permission_type: 'account',
                        partner_name: partnerName,
                    },
                };
            },
        }),
    }),
});

export const getPartnerByPartnerName = createSelector(partnerApi.endpoints.getPartners.select(), partners =>
    partners?.data ? findPartner(partners.data) : undefined
);

export const { useGetPartnerConnectionsQuery, useGetPartnersQuery, usePutPartnerConnectionMutation } = partnerApi;
