import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import type React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
    privacyPolicyUrl: string;
    children: React.ReactNode;
}

export const PrivacyPolicyOpener = ({ privacyPolicyUrl, children }: Props) => {
    const [show, setShow] = useState(false);
    const intl = useIntl();

    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setShow(true);
    };
    const onHide = () => setShow(false);

    return (
        <>
            <Dialog
                show={show}
                title={<FormattedMessage id={'contactForm.privacyPolicy.title'} />}
                body={
                    <iframe
                        className='position-absolute top-0 left-0 width-100pct height-100pct'
                        src={`${privacyPolicyUrl}#toolbar=0`}
                        title={intl.formatMessage({ id: 'contactForm.privacyPolicy.title' })}
                    />
                }
                onHide={onHide}
                footer={null}
                showCloseButton={true}
                bsSize={Dialog.SIZE_FULL_SCREEN}
            />
            <span onClick={onClick}>
                <a href={privacyPolicyUrl}>{children}</a>
            </span>
        </>
    );
};
