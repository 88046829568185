import { createRoutesFromElements, Route, createBrowserRouter } from 'react-router-dom';

import AppLayout from '../layout/AppLayout';
import { ContactFormContainer } from '../features/app/contact/ContactForm';
import { PartnerForm } from '../features/app/partner/PartnerForm';
export const CONTACT_ROUTE = '/contact';
export const PARTNER_ROUTE = '/partner';
export const routes = [CONTACT_ROUTE, PARTNER_ROUTE];

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/' element={<AppLayout />}>
            <Route path={CONTACT_ROUTE} element={<ContactFormContainer />} />
            <Route path={PARTNER_ROUTE} element={<PartnerForm />} />
        </Route>
    )
);
