import { userProfileObtained, userSessionExpired, userSessionRenewed } from './loginSlice';
import { configureMockUserManager, configureUserManager, createUserManager } from './login';
import { accessToken } from '../tokenHandling/accessToken';
import { config } from '../../config';
import { store } from '../setup/store';
import { accessTokenStored, idTokenStored } from '../tokenHandling/tokenSlice';
import { trace } from '../setup/trace';
import { reportErrorToSentry } from '../setup/sentry';
import { attemptInitialSignIn } from '../setup/oauth';

function loginGuard() {
    const oauthConfig = {
        onSessionExpired: () => {
            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onSessionRenewed: (result: any) => {
            trace('index.onTokenRenewed', result);

            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(result.idToken));
            store.dispatch(userProfileObtained(result.profile));
            store.dispatch(userSessionRenewed());
        },
    };

    const isAllowedToMockAuth = process.env.NODE_ENV !== 'production';
    const userManager =
        isAllowedToMockAuth && config.login.mockAuthorization
            ? configureMockUserManager(oauthConfig)
            : configureUserManager(oauthConfig, createUserManager());

    try {
        userManager.clearStaleState().catch(error => reportErrorToSentry(error));
    } catch (error) {
        reportErrorToSentry(error);
    }

    return attemptInitialSignIn(userManager)
        .then(() => {
            return true;
        })
        .catch(error => {
            trace('could not start application', error);
            return false;
        });
}

export { loginGuard };
