import { useEffect } from 'react';
import { TextInput } from './TextInput';
import type { CustomerData } from './redux/types';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { formElementsActions, getCustomerData } from './redux/formElements.redux';
import { TitleInput } from './TitleInput';
import type { RootState } from '../../../configuration/setup/store';
import { getIdToken } from '../../../configuration/tokenHandling/tokenSlice';

interface Props {
    customerData: CustomerData;
    setCustomerData: (data: CustomerData) => void;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    idToken?: any;
}

export const CustomerForm = ({ customerData, setCustomerData, idToken }: Props) => {
    useEffect(() => {
        setCustomerData({
            ...customerData,
            firstName: idToken?.given_name ?? '',
            lastName: idToken?.family_name ?? '',
            email: idToken?.email ?? '',
            tenant: idToken?.tenant ?? '',
        });
        // it is wanted that the values are set when idToken changes
    }, [idToken]);

    const setTitle = (title: string) => setCustomerData({ ...customerData, title });
    const setFirstName = (firstName: string) => setCustomerData({ ...customerData, firstName });
    const setLastName = (lastName: string) => setCustomerData({ ...customerData, lastName });
    const setCompany = (company: string) => setCustomerData({ ...customerData, company });
    const setEmail = (email: string) => setCustomerData({ ...customerData, email });
    const setPhoneNumber = (phoneNumber: string) => setCustomerData({ ...customerData, phoneNumber });
    return (
        <div>
            <div className='col-xs-12 col-ls-2'>
                <TitleInput setValue={setTitle} />
            </div>
            <div className='col-xs-12 col-ls-5'>
                <TextInput
                    id={'contactForm.firstName'}
                    required={false}
                    setValue={setFirstName}
                    value={customerData.firstName}
                    type={'text'}
                    maxLength={40}
                />
            </div>
            <div className='col-xs-12 col-ls-5'>
                <TextInput
                    id={'contactForm.lastName'}
                    required={false}
                    setValue={setLastName}
                    value={customerData.lastName}
                    type={'text'}
                    maxLength={40}
                />
            </div>
            <div className='col-xs-12 col-ls-4'>
                <TextInput
                    id={'contactForm.company'}
                    required={false}
                    setValue={setCompany}
                    value={customerData.company}
                    type={'text'}
                    maxLength={80}
                />
            </div>
            <div className='col-xs-12 col-ls-4'>
                <TextInput
                    id={'contactForm.email'}
                    required={true}
                    setValue={setEmail}
                    value={customerData.email}
                    type={'email'}
                    maxLength={80}
                />
            </div>
            <div className='col-xs-12 col-ls-4'>
                <TextInput
                    id={'contactForm.phoneNumber'}
                    required={false}
                    setValue={setPhoneNumber}
                    value={customerData.phoneNumber}
                    type={'text'}
                    maxLength={40}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    customerData: getCustomerData(state),
    idToken: getIdToken(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setCustomerData: (data: CustomerData) => dispatch(formElementsActions.setCustomerData(data)),
});

export const CustomerFormContainer = connect(mapStateToProps, mapDispatchToProps)(CustomerForm);
