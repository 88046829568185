import { DEFAULT_LOCALE, getSupportedLocale, supportedLocaleMap } from './lang';
import { extractQueryParameter } from '../../features/utils/queryParameters';

// This code also exists in the buy-button, contact-form-frontend and the menu-web. Consider changing it at all places
export function extractLocaleFromOpenMarketplaceReferer(referer: string) {
    if (referer.startsWith('https://americas.rio.cloud')) {
        return 'pt-BR';
    }
    if (referer.startsWith('https://rio.cloud/')) {
        const openMarketplaceLocale = referer.split('/')[3];
        if (!openMarketplaceLocale) {
            return null;
        }
        const convertedLocale = countryToLocale[openMarketplaceLocale.toLowerCase()];
        return convertedLocale ? convertedLocale : openMarketplaceLocale;
    }
    return null;
}

const countryToLocale: any = {
    cz: 'cs',
    dk: 'da',
    ee: 'et',
    gr: 'el',
    no: 'nb',
    se: 'sv',
    si: 'sl',
};

export function getUrlOrBrowserLocale() {
    const urlLocale = extractQueryParameter('locale');
    if (urlLocale !== undefined) {
        return getSupportedLocale(urlLocale);
    }
    const openMarketplaceLocale = extractLocaleFromOpenMarketplaceReferer(document.referrer);
    const windowLanguage = openMarketplaceLocale ? openMarketplaceLocale : window.navigator.language.substring(0, 5);
    if (Object.values(supportedLocaleMap).includes(windowLanguage)) {
        return windowLanguage;
    }if (Object.keys(supportedLocaleMap).includes(windowLanguage.substring(0, 2))) {
        return supportedLocaleMap[windowLanguage.substring(0, 2)];
    }
    return DEFAULT_LOCALE;
}
