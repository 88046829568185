import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getContactData } from '../formElements/redux/formElements.redux';
import type { RootState } from '../../../configuration/setup/store';

interface Props {
    showTitle: boolean;
}

const ContactFormHeader = ({ showTitle }: Props) => {
    return showTitle ? (
        <div className='col-xs-12 text-center'>
            <h1 className='margin-top-0 margin-bottom-2pct'>
                <FormattedMessage id={'contactForm.contactButtonText'} />
            </h1>
        </div>
    ) : (
        <></>
    );
};

const mapStateToProps = (state: RootState) => ({
    showTitle: !getContactData(state).hideTitle,
});

export const ContactFormHeaderContainer = connect(mapStateToProps)(ContactFormHeader);
