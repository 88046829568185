import type React from 'react';
import { useIntl } from 'react-intl';

interface Props {
    id: string;
    required: boolean;
    setValue: (value: string) => void;
    value: string;
    type: string;
    maxLength: number;
}

export const TextInput = ({ id, required, value, setValue, type, maxLength }: Props) => {
    const intl = useIntl();
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value);

    return (
        <div className='form-group'>
            <label className='control-label' htmlFor={id}>
                {intl.formatMessage({ id })}
                {required ? <span className='required'>*</span> : ''}
            </label>
            <input
                id={id}
                className='form-control'
                placeholder={intl.formatMessage({ id })}
                type={type}
                required={required}
                value={value}
                onChange={onChange}
                maxLength={maxLength}
            />
        </div>
    );
};
