import type React from 'react';
import { FormattedMessage } from 'react-intl';
import { PrivacyPolicyCheckbox } from '../formElements/PrivacyPolicyCheckbox';
import { type Partner, usePutPartnerConnectionMutation } from '../api/partnerApi';
import { SubmitForm } from '../formElements/SubmitForm';
import { gaLabelsForPartnerClick } from '../gtm/gtmLabels';
import partnerConnection from './images/partner_connection.svg';
import { CustomerCenterLinkWrapper } from './CustomerCenterLink';

interface Props {
    submitRequest: (event: React.FormEvent<HTMLElement>) => void;
    partner: Partner;
}

export const PartnerRequestFormContent = ({ submitRequest, partner }: Props) => {
    const { partnerName, partnerDisplayName, privacyPolicyUrl } = partner;
    const { isLoading: isSubmitting } = usePutPartnerConnectionMutation()[1];

    return (
        <div className='container-fluid fluid-small'>
            <form onSubmit={submitRequest}>
                <div className='col-xs-12 text-center'>
                    <h1 className='margin-bottom-5pct'>{partnerDisplayName}</h1>
                </div>
                <div className='col-xs-12 display-flex justify-content-center'>
                    <img
                        className={'ProductIcon margin-top-25 margin-bottom-25 img-responsive max-width-600'}
                        src={partnerConnection}
                        alt={'partner-connection'}
                    />
                </div>
                <div className='col-xs-12 gap-5 display-flex flex-column align-items-center margin-bottom-5pct margin-top-2pct text-center text-size-18'>
                    <div>
                        <FormattedMessage id={'contactForm.partner.disclaimerDataEnabling'} />
                    </div>
                    <div>
                        <FormattedMessage id={'contactForm.partner.disclaimerWaiting'} />
                    </div>
                    <div>
                        <FormattedMessage
                            id={'contactForm.partner.disclaimerDeleteConnection'}
                            values={{
                                link: chunks => <CustomerCenterLinkWrapper>{chunks}</CustomerCenterLinkWrapper>,
                            }}
                        />
                    </div>
                </div>
                <div className='row'>
                    <SubmitForm
                        isSending={isSubmitting}
                        label={'contactForm.connectPartner'}
                        gaTrackingOnSend={gaLabelsForPartnerClick(partnerName)}
                        iconClass={'rioglyph rioglyph-compare'}
                    >
                        <PrivacyPolicyCheckbox
                            privacyPolicyUrl={privacyPolicyUrl}
                            checkBoxLabel={'contactForm.privacyPolicy'}
                        />
                    </SubmitForm>
                </div>
            </form>
        </div>
    );
};
