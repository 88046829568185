import type React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
    isSending: boolean;
    label: string;
    gaTrackingOnSend: Object;
    children?: React.ReactNode;
    iconClass?: string;
}

export const SubmitForm = ({ isSending, gaTrackingOnSend, label, children, iconClass }: Props) => {
    return (
        <div className='col-xs-12'>
            <hr className='border-color-light' />
            <div className='display-flex-ls justify-content-between-ls align-items-center-ls'>
                <div>
                    {children && children}
                    <div className='margin-bottom-25-xs text-color-dark'>
                        <FormattedMessage id={'contactForm.requiredInfo'} />
                    </div>
                </div>
                <button className='btn btn-primary' type='submit' disabled={isSending} {...gaTrackingOnSend}>
                    {iconClass && <span className={iconClass} />}
                    <FormattedMessage id={label} />
                </button>
            </div>
        </div>
    );
};
