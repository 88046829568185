import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import configReducer from './configSlice';
import loginReducer from '../login/loginSlice';
import langReducer from '../lang/langSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import appReducer from '../../data/appSlice';
import { formElementsActions, formElementsReducer } from '../../features/app/formElements/redux/formElements.redux';
import { partnerApi } from '../../features/app/api/partnerApi';

export const store = configureStore({
    reducer: {
        config: configReducer,
        lang: langReducer,
        app: appReducer,
        login: loginReducer,
        tokenHandling: tokenReducer,
        formElements: formElementsReducer,
        [partnerApi.reducerPath]: partnerApi.reducer,

        // Add the generated reducer as a specific top-level slice
    },

    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [formElementsActions.setFiles.type, formElementsActions.setContactData.type],
                ignoredPaths: ['formElements.contactData.files'],
            },
        }).concat(partnerApi.middleware),
});

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
