import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';

export function sendAndHandleError(
    setSuccessfullySent: (sending: boolean) => void,
    errorMessage: string,
    setIsSending: (sending: boolean) => void,
    sendData: () => Promise<Response>
) {
    setIsSending(true);
    sendData()
        .then(response => {
            if (response.ok) {
                setSuccessfullySent(true);
            } else {
                Notification.error(errorMessage);
            }
            setIsSending(false);
        })
        .catch(() => {
            Notification.error(errorMessage);
            setIsSending(false);
        });
}
