import type React from 'react';
import { useMemo } from 'react';
import isEmpty from 'lodash/fp/isEmpty';

import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import FilePicker from '@rio-cloud/rio-uikit/FilePicker';
import { FormattedMessage, useIntl } from 'react-intl';
import { fileTypeHelper } from './utils';
import Tag from '@rio-cloud/rio-uikit/Tag';
import TagList from '@rio-cloud/rio-uikit/TagList';

const TOTAL_FILES_SIZE_LIMIT = 15728640; // 15MB

interface FilePickerProps {
    setFiles: (files: File[]) => void;
    files: File[];
}
const FilePickerComponent: React.FC<FilePickerProps> = ({ files, setFiles }) => {
    const { mimeTypes, extensions } = useMemo(() => fileTypeHelper(), []);
    const intl = useIntl();

    const handlePick = (acceptedFiles: File[]) => {
        const totalSize = acceptedFiles.reduce((acc, file) => acc + file.size, 0);
        const invalidFiles = acceptedFiles.filter(file => !mimeTypes.includes(file.type));

        if (totalSize > TOTAL_FILES_SIZE_LIMIT) {
            Notification.error(
                <FormattedMessage
                    id={'contactForm.fileSizeExceedsLimit'}
                    defaultMessage={'Total files size exceeds the limit of {limit} MB'}
                    values={{ limit: TOTAL_FILES_SIZE_LIMIT / 1024 / 1024 }}
                />
            );
            return;
        }
        if (invalidFiles.length) {
            Notification.error(
                <FormattedMessage
                    id={'contactForm.error.invalidFileTypes'}
                    defaultMessage={'Invalid file types. Please upload only {allowedFileTypes} files'}
                    values={{ allowedFileTypes: extensions.join(', ') }}
                />
            );
            return;
        }

        setFiles(acceptedFiles);
    };

    const removeByPath = (fileName: string) => {
        const newFiles = files.filter(file => file.name !== fileName);
        setFiles(newFiles);
    };

    return (
        <div>
            <div className='col-xs-12 col-ls-2 padding-left-0'>
                <FilePicker
                    displayMode={FilePicker.DISPLAY_MODE_BUTTON}
                    label={intl.formatMessage({ id: 'contactForm.attachments.button' })}
                    onPick={handlePick as any}
                    multiple={true}
                >
                    {({ isDragActive }) => <DropArea isDragActive={isDragActive} />}
                </FilePicker>
            </div>
            <div className='col-xs-12 col-ls-10 max-height-60 overflow-y-auto'>
                {!isEmpty(files) && (
                    <TagList>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                            {files.map(file => (
                                <FilePreview key={file.name} file={file} onFileRemove={removeByPath} />
                            ))}
                        </div>
                    </TagList>
                )}
            </div>
        </div>
    );
};

const DropArea = ({ isDragActive }: any) => (
    <div className='rounded border border-color-gray border-style-dashed bg-white'>
        <div
            className={'bg-lightest text-size-14 text-center text-color-darkest opacity-50 cursor-pointer padding-y-10'}
        >
            <span className='rioglyph rioglyph-files text-size-200pct opacity-50 margin-top-20' />
            <div className='margin-top-10'>
                {isDragActive ? (
                    <FormattedMessage id={'contactForm.dropzone.dropAttachments'} />
                ) : (
                    <FormattedMessage id={'contactForm.dropzone.dragAttachments'} />
                )}
            </div>
        </div>
    </div>
);

interface FilePreviewProps {
    file: File;
    onFileRemove: (fileName: string) => void;
}

const FilePreview: React.FC<FilePreviewProps> = ({ file, onFileRemove }) => {
    return (
        <span onClick={() => onFileRemove(file.name)}>
            <Tag deletable size='small'>
                {file.name}
            </Tag>
        </span>
    );
};

export default FilePickerComponent;
